<template>
    <div class="relative" ref="fingerTap">
        <!-- First div -->
        <div class="absolute top-[-45px] right-[16px] z-10">
            <i class="fa-solid fa-circle opacity-0" id="pulse"></i>
        </div>

        <!-- Second div -->
        <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20">
            <i class="fa-solid fa-hand-point-up text-8xl shadow-lg" id="finger"></i>
        </div>
    </div>
</template>
  
<script>
export default {
    mounted() {
        this.intersectionObv();
    },
    methods: {
        intersectionObv() {
            let finger = document.getElementById("finger");
            let pulse = document.getElementById("pulse");

            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        finger.classList.add("skewed-img");
                        pulse.classList.add("pulse-icon");
                    } else {
                        finger.classList.remove("skewed-img");
                        pulse.classList.remove("pulse-icon");
                    }
                });
            });

            // Observe the elements
            observer.observe(finger);
            observer.observe(pulse);
        },
    },
};
</script>
  
<style scoped>
.skewed-img {
    animation-name: clickicon;
    animation-duration: 2s;

    opacity: 0;
}

.pulse-icon {
    position: relative;
    animation-name: clickCircle;
    animation-duration: 2s;
    animation-delay: 0.83s;
    transform-origin: center;
    /* Set the transform origin */
}

.fade-out-finger {
    animation: fadeOut forwards;
    animation-duration: 1s;
    animation-delay: 1.75s;
}

@keyframes clickicon {
    0% {
        transform: rotate3d(1, 0, 0, 0deg);
        opacity: 100%;
    }

    40% {
        transform: rotate3d(1, 0, 0, 40deg);
    }

    80% {
        transform: rotate3d(1, 0, 0, 0deg);
        opacity: 100%;
    }

    100% {
        opacity: 0;
        display: none;
    }
}

@keyframes clickCircle {
    0% {
        transform: scale(1);
        opacity: 1;
        transform-origin: center;
    }

    100% {
        transform: scale(8);
        opacity: 0;
        transform-origin: center;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    99% {
        opacity: 0;
    }

    100% {
        opacity: 0;
        display: none;
    }
}
</style>
  