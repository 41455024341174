<template>
    <section id="portfolio" class="py-5">
        <section id="portfolio-automotive">
            <div class="absolute w-full pointer-events-none z-30">
                <div class="grid md:grid-cols-4 grid-cols-3">
                    <div class="hidden md:block"></div>

                    <div class="text-white w-full aspect-square flex items-center justify-center md:mt-0 mt-12">
                        <FingerTap class="scale-75 md:scale-90 lg:scale-100" />
                    </div>
                </div>
            </div>

            <div class="grid md:grid-cols-4 grid-cols-3">
                <div class="text-right p-5 hidden md:block">
                    <div class="sticky top-16">
                        <h3 class="text-2xl font-semibold text-gray-300">
                            {{ portfolioSection.automotiveTitle }}
                        </h3>
                        <p class="text-gray-400">{{ portfolioSection.automotiveTagline }}</p>
                    </div>
                </div>
                <div class="col-span-3 md:ml-[4px]">
                    <h3 class="text-2xl font-semibold sticky top-16 text-gray-300 block md:hidden bg-black p-3 z-30">
                        {{ portfolioSection.automotiveTitle }}
                    </h3>

                    <div class="grid grid-cols-3 lg:gap-8 gap-2 p-3">
                        <PortfolioImg v-for="image in portfolioSection.automotive" :key="image.link" :src="image.link"
                            :thumbPos="image.thumbnailPos" />
                    </div>
                </div>
            </div>
        </section>


        <section id="portfolio-portrait">
            <div class="grid md:grid-cols-4 grid-cols-3 lg:mt-4">
                <div class="col-span-3">
                    <h3 class="text-2xl font-semibold sticky top-16 text-gray-300 block md:hidden bg-black p-3">
                        {{ portfolioSection.portraitTitle }}
                    </h3>

                    <div class="grid grid-cols-3 lg:gap-8 gap-2 p-3">
                        <PortfolioImg v-for="image in portfolioSection.portrait" :key="image.link" :src="image.link"
                            :thumbPos="image.thumbnailPos" />
                    </div>
                </div>
                <div class="text-left p-5 hidden md:block">
                    <div class="sticky top-16">
                        <h3 class="text-2xl font-semibold text-gray-300">
                            {{ portfolioSection.portraitTitle }}
                        </h3>
                        <p class="text-gray-400">{{ portfolioSection.portraitTagline }}</p>
                    </div>
                </div>
            </div>
        </section>


        <section id="portfolio-realestate">
            <div class="grid md:grid-cols-4 grid-cols-3 lg:mt-4">
                <div class="text-right p-5 hidden md:block">
                    <div class="sticky top-16">
                        <h3 class="text-2xl font-semibold text-gray-300">
                            {{ portfolioSection.realestateTitle }}
                        </h3>
                        <p class="text-gray-400">{{ portfolioSection.realestateTagline }}</p>
                    </div>
                </div>
                <div class="col-span-3">
                    <h3 class="text-2xl font-semibold sticky top-16 text-gray-300 block md:hidden bg-black p-3">
                        {{ portfolioSection.realestateTitle }}
                    </h3>

                    <div class="grid grid-cols-3 lg:gap-8 gap-2 p-3">
                        <PortfolioImg v-for="image in portfolioSection.realestate" :key="image.link" :src="image.link"
                            :thumbPos="image.thumbnailPos" />
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>
  
<script>
import { defineComponent } from 'vue';
import { portfolioSection } from '@/constants';

import PortfolioImg from './PortfolioImg.vue';
import FingerTap from './FingerTap.vue';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
    components: {
        PortfolioImg,
        FingerTap,
    },
    data: () => ({
        currentSlide: 0,
        portfolioSection: portfolioSection,
    }),
    methods: {
        slideTo(index) {
            this.currentSlide = index;
        },
    },
});
</script>
  