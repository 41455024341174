<template>
    <section id="about">
        <div class="lg:flex overflow-x-hidden pt-24">
            <div class="container mx-auto hidden lg:flex items-center">
                <div class="lg:w-1/2 p-4">
                    <div>
                        <h2 class="text-3xl font-semibold mb-4 text-gray-100">{{ aboutSection.title }}</h2>
                        <p class="text-gray-200 text-left header-font">
                            {{ aboutSection.text }}
                            <ContactStrip />
                        </p>
                    </div>
                </div>

                <div class="lg:w-1/2 p-4 mt-4 lg:mt-0 flex justify-center items-center">
                    <div>
                        <img :src="aboutSection.picture" alt="Profile Image"
                            class="rounded-full shadow-lg w-96 h-96 object-cover" />
                    </div>
                </div>
            </div>

            <div class="container mx-auto lg:hidden block items-center">
                <div class="w-full p-4 mt-4 lg:mt-0 flex justify-center items-center">
                    <div>
                        <img :src="aboutSection.picture" alt="Profile Image"
                            class="rounded-full shadow-lg w-96 h-96 object-cover" />
                    </div>
                </div>

                <div class="w-full p-4">
                    <div>
                        <h2 class="text-3xl font-semibold mb-4 text-gray-100">{{ aboutSection.title }}</h2>
                        <p class="text-gray-200 text-left header-font">
                            {{ aboutSection.text }}
                            <ContactStrip />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
  
<script>
import { aboutSection } from '@/constants'
import ContactStrip from './ContactStrip.vue';

export default {
    components: {
        ContactStrip,
    },
    data() {
        return {
            aboutSection: aboutSection,
        }
    }
}
</script>
  