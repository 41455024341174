export const c = 'https://pub-7715320919f94c94b5ac416443b915a8.r2.dev/';

export const navBranding = {
    logo: c + 'web-logo.png',
    text: 'COOK PHOTOGRAPHY',
};

export const socials = {
    facebook: 'https://www.facebook.com/people/Cook-Photography/61552646819955/',
    instagram: 'https://www.instagram.com/cookphotossi/',
    phone: 'tel:2502210204',
    email: 'mailto:levi@cookphoto.ca',
};

export const navLinks = [
    {
        text: 'Facebook',
        icon: 'fa-brands fa-facebook',
        link: socials.facebook
    },
    {
        text: 'Instagram',
        icon: 'fa-brands fa-instagram',
        link: socials.instagram
    },
    {
        text: '(250) 221-0204',
        icon: 'fa-solid fa-phone',
        link: socials.phone
    },
    {
        text: 'Levi@CookPhoto.ca',
        icon: 'fa-solid fa-envelope',
        link: socials.email
    },
];

export const pageHeader = {
    title: 'COOK PHOTOGRAPHY',
    tagline: 'Photographer | Salt Spring Island',
    buttonText: 'Contact for Booking',
    headerBackground: c + 'Header.jpg',
};

export const aboutSection = {
    picture: c + 'Headshot 2.jpg',
    title: 'About Me',
    text: "I'm Levi Cook, a freelance photographer on Salt Spring Island. With a lifelong passion for photography, I specialize in real estate, automotive (including motorcycles), pet, and portrait photography, using natural light for stunning results. Open to special requests, I aim to capture the essence of each subject, creating timeless photos. Outside photography, I explore on my motorcycle, continually learning and incorporating new concepts into my work. If you're interested in collaborating, contact me to discuss creating beautiful photographs together.",
    contact: 'If you have any questions feel free to contact me',
}

export const servicesSection = {
    title: 'Services',
    cards: [
        {
            title: 'Automotive Photography',
            text: "Unleash the power of visuals for your vehicles. From sleek cars to roaring motorcycles, I specialize in capturing the essence of your automotive pride.Every detail, every angle, in a striking composition.",
            image: c + 'Automotive2.jpg',
            section: 'portfolio-automotive',
        },
        {
            title: 'Portrait Photography',
            text: "Embrace the beauty of companionship. Professional pet and portrait photography that immortalizes the bond between you and your loved ones. Natural light, genuine moments, timeless memories.",
            image: c + 'Portrait1.jpg',
            section: 'portfolio-portrait',
        },
        {
            title: 'Real Estate Photography',
            text: "Capturing spaces in a new light. High-quality real estate photography to showcase properties at their best. Whether it's residential or commercial, let your space shine.",
            image: c + 'davids-house-01-1600x900.jpg',
            section: 'portfolio-realestate',
        },
    ],
    queriesText: 'Interested in something else? Feel free to contact me with any inqueries, questions, or concerns.',
    buttonText: 'Contact',
}

/* To add a new image, copy the structure below, paste it in whichever position in the list you want it to be rendered

        {
            link: c + 'image.jpeg',
            thumbnailPos: 'center'
        },

 * thumbnailPos accepts left, right, top, bottom, and center
 * thumbnailPos can take in 2 values at a time. If no second value is provided, it will be "center"
 * 
 * Usage examples:
 * thumbnailPos: left top <-- Shows the top left area
 * thumbnailPos: bottom <-- Ensures the user sees the bottom edge
 * thumbnailPos: right center <-- Ensures you will see the right edge of the image, and will center it
 * 
 * For more info: https://www.w3schools.com/cssref/pr_background-position.php
 */

export const portfolioSection = {
    automotiveTitle: 'Automotive',
    automotiveTagline: 'Capture your pride and joy from every angle',

    automotive: [
        {
            link: c + 'Automotive1.jpg',
            thumbnailPos: 'center'
        },
        {
            link: c + 'Automotive2.jpg',
            thumbnailPos: 'center'
        },
        {
            link: c + 'Automotive3.jpg',
            thumbnailPos: 'center'
        },
        {
            link: c + 'Automotive4.jpg',
            thumbnailPos: 'center'
        },
        {
            link: c + 'Automotive5.jpg',
            thumbnailPos: 'center'
        },
        {
            link: c + 'Automotive6.jpg',
            thumbnailPos: 'center'
        },
        {
            link: c + 'Automotive7.jpg',
            thumbnailPos: 'center'
        },
        {
            link: c + 'Automotive8.jpg',
            thumbnailPos: 'center'
        },
        {
            link: c + 'Automotive9.jpg',
            thumbnailPos: 'center'
        },
        {
            link: c + 'Automotive10.jpg',
            thumbnailPos: 'center'
        },
        {
            link: c + 'Automotive11.jpg',
            thumbnailPos: 'center'
        },
        {
            link: c + 'Automotive12.jpg',
            thumbnailPos: 'center'
        },
    ],

    portraitTitle: 'Portraits',
    portraitTagline: 'Capture the natural beauty of you, your family, or you furry friends',

    portrait: [
        {
            link: c + 'Portrait1.jpg',
            thumbnailPos: 'right'
        },
        {
            link: c + 'Portrait2.jpg',
            thumbnailPos: 'center'
        },
        {
            link: c + 'Portrait3.jpg',
            thumbnailPos: 'top'
        },
        {
            link: c + 'Portrait4.jpg',
            thumbnailPos: 'center'
        },
        {
            link: c + 'Portrait5.jpg',
            thumbnailPos: 'top'
        },
        {
            link: c + 'Portrait6.jpg',
            thumbnailPos: 'top'
        },
        {
            link: c + 'Portrait7.jpg',
            thumbnailPos: 'top'
        },
        {
            link: c + 'Portrait8.jpg',
            thumbnailPos: 'top'
        },
        {
            link: c + 'Portrait9.jpg',
            thumbnailPos: 'center'
        },
        {
            link: c + 'Portrait10.jpg',
            thumbnailPos: 'center'
        },
        {
            link: c + 'Portrait11.jpg',
            thumbnailPos: 'center'
        },
        {
            link: c + 'Portrait12.jpg',
            thumbnailPos: 'center'
        },
        {
            link: c + 'Portrait13.jpg',
            thumbnailPos: '0% 20%'
        },
        {
            link: c + 'Portrait14.jpg',
            thumbnailPos: 'center'
        },
        {
            link: c + 'Portrait15.jpg',
            thumbnailPos: 'center'
        },
    ],

    realestateTitle: 'Real Estate',
    realestateTagline: 'Photos coming soon!',

    realestate: [
        {
            link: c + 'realestate-1.jpeg',
            thumbnailPos: 'center'
        },
        {
            link: c + 'realestate-2.jpeg',
            thumbnailPos: 'center'
        },
        {
            link: c + 'realestate-3.jpeg',
            thumbnailPos: 'center'
        },
    ],
}

export const contactSection = {
    background: c + 'contact-background.jpeg',

    text: 'Let\'s work together!',
    tagline: 'Send me a message and I\'ll get back to you asap.',

    nameTitle: 'Your Name',
    namePlaceholder: 'John Doe',

    emailTitle: 'Your Email',
    emailPlaceholder: 'john@example.com',

    phoneTitle: 'Phone Number (Optional)',
    phonePlaceholder: '(250) 123-4567',

    messageTitle: 'Your Message',
    messagePlaceholder: 'Write your message here',

    buttonText: 'Send Email',

    successText: 'Thank you for contacting me! I will reach out to you shortly!',
    failedText: 'An unknown error occured. Please contact me at leviscook123@gmail.com',
}
