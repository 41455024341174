<template>
    <div class="w-full aspect-square bg-cover transition cursor-pointer hover:scale-[1.025]"
        :style="{ 'background-image': 'url(' + src + ')', 'background-position': thumbPos }" @click="openModal">
    </div>

    <div v-if="modalOpen"
        class="bg-acrylic bg-opacity-90 bg-[#101010] fixed top-0 left-0 w-full h-full z-40 flex items-center justify-center pt-10"
        @click="closeModal">
        <div class="max-h-[95%] relative">
            <img :src="src" class="w-full h-full object-contain" :style="{ 'max-height': maxHeight }">

            <div class="bg-[#a0a0a095] w-8 h-8 rounded-full flex items-center justify-center p-2 absolute top-0 right-0 m-1">
                <i class="fa-solid fa-xmark text-white fa-xl cursor-pointer"></i>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            modalOpen: false,
            maxHeight: '80vh',
        };
    },
    props: {
        src: String,
        thumbPos: String,
    },
    methods: {
        openModal() {
            this.modalOpen = true;
            document.body.style.overflow = 'hidden';
        },
        closeModal() {
            this.modalOpen = false;
            document.body.style.overflow = '';
        },
    },
};
</script>
  