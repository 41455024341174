<template>
    <section id="home">
        <div class="relative h-screen overflow-hidden flex items-center">
            <div class="inset-0 bg-cover bg-center absolute"
                :style="{ 'background-image': 'url(' + pageHeader.headerBackground + ')', 'filter': 'brightness(50%)', 'animation': 'fadeIn 2s ease' }">
            </div>
            <div class="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black h-full"></div>
            <div class="container mx-auto p-4 text-white relative z-10 mb-[50vh]">
                <h1
                    class="header-font md:text-5xl text-2xl font-extrabold uppercase tracking-wider animate__animated animate__fadeIn animate__delay-2000 transition-opacity duration-1000 ease-in transform slide-up text-shadow">
                    {{ pageHeader.title }}
                </h1>
                <p
                    class="navbar-font md:text-2xl text-xl animate__animated animate__fadeIn animate__delay-2000 transition-opacity duration-1000 ease-in transform slide-up mb-2">
                    {{ pageHeader.tagline }}
                </p>

                <button @click="scrollToSection('contact')"
                    class="btn animate__animated animate__fadeIn animate__delay-2000 transition-opacity duration-2000 ease-in-out transform slide-up">
                    {{ pageHeader.buttonText }}
                </button>
            </div>
        </div>
    </section>
</template>
  
<script>
import { pageHeader } from '@/constants'

export default {
    data() {
        return {
            pageHeader: pageHeader,
        };
    },
    methods: {
        scrollToSection(sectionId) {
            const section = document.getElementById(sectionId);

            if (section) {
                window.scrollTo({
                    top: section.offsetTop,
                    behavior: 'smooth',
                });
            }
        },
    }
};
</script>
  
<style>
/* You may need to add the necessary classes for animations. */
@keyframes fadeInSlideUp {
    from {
        opacity: 0;
        transform: translateY(50px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.slide-up {
    animation-delay: 1s;
    animation: fadeInSlideUp 1.5s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.text-shadow {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
</style>
  