<template>
    <section id="services">
        <div class="services-background md:py-14 md:pb-0 pb-12 md:mb-12">
            <h2 class="text-3xl font-semibold mb-4 text-gray-300">
                {{ servicesSection.title }}</h2>
            <div class="container mx-auto flex flex-col md:flex-row justify-center">
                <!-- Service Card 1 -->

                <div v-for="card in servicesSection.cards" :key="card.title" @click="scrollToSection(card.section)"
                    class="cursor-pointer bg-gray-100 rounded-lg overflow-hidden m-4 md:w-1/3 shadow-lg hover:scale-105 transition">
                    <img :src="card.image" alt="Service 1" class="w-full h-48 object-cover" />
                    <div class="p-6">
                        <h3 class="text-2xl font-semibold text-gray-600">{{ card.title }}</h3>
                        <p class="text-gray-500 mt-4 text-base md:text-sm lg:text-base">
                            {{ card.text }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="container mx-auto flex flex-col md:flex-row jusitfy-center">
                <div class="rounded-lg overflow-hidden my-4 w-full shadow-lg">
                    <p class="text-white">{{ servicesSection.queriesText }}</p>

                    <button class="btn mt-3" @click="scrollToSection('contact')">{{ servicesSection.buttonText }}</button>
                </div>
            </div>
        </div>
    </section>
</template>
  
<script>
import { servicesSection } from '@/constants';

export default {
    data() {
        return {
            servicesSection: servicesSection,
        }
    },
    methods: {
        scrollToSection(sectionId) {
            const section = document.getElementById(sectionId);

            if (section) {
                window.scrollTo({
                    top: section.offsetTop,
                    behavior: 'smooth',
                });
            }
        },
    }
};
</script>
  
<style scoped>
.services-background {
    background: linear-gradient(to bottom, black 0%, #0a0a0a 30%, #0a0a0a 70%, black 100%);
}
</style>