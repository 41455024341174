<template>
    <section id="contact">

        <!-- Desktop view -->
        <div class="hidden lg:grid grid-cols-2 mx-center text-left">
            <div class="bg-cover bg-center flex items-center justify-center"
                :style="{ 'background-image': 'url(' + contactSection.background + ')' }">
                <div class="text-center p-5 bg-[#43434380] rounded-lg">
                    <h2 class="text-white text-2xl">Let's work together!</h2>
                    <p class="text-white ">Send me a message and I'll get back to you asap.</p>
                </div>
            </div>

            <div class="bg-[#353535] px-12 flex justify-center items-center h-[50vh] text-white">
                <form @submit.prevent="submitForm">
                    <div class="grid grid-cols-3 gap-3 w-full">
                        <div class="mb-4 col-span-3 lg:col-span-1">
                            <label for="name" class="block text-sm font-medium">
                                <i class="fa-solid fa-user"></i> {{ contactSection.nameTitle }}
                            </label>
                            <input type="text" id="name" name="name" v-model="formData.name"
                                class="mt-1 p-2 w-full border bg-[#353535] border-x-0 border-t-0 focus:outline-none focus:border-[#f0ad1d]"
                                :placeholder="contactSection.namePlaceholder" required />
                        </div>

                        <div class="mb-4 col-span-3 lg:col-span-1">
                            <label for="name" class="block text-sm font-medium">
                                <i class="fa-solid fa-envelope"></i> {{ contactSection.emailTitle }}
                            </label>
                            <input type="text" id="email" name="email" v-model="formData.email"
                                class="mt-1 p-2 w-full border bg-[#353535] border-x-0 border-t-0 focus:outline-none focus:border-[#f0ad1d]"
                                :placeholder="contactSection.emailPlaceholder" required />
                        </div>

                        <div class="mb-4 col-span-3 lg:col-span-1">
                            <label for="name" class="block text-sm font-medium">
                                <i class="fa-solid fa-phone"></i> {{ contactSection.phoneTitle }}
                            </label>
                            <input type="tel" id="phone" name="phone" v-model="formData.phone"
                                class="mt-1 p-2 w-full border bg-[#353535] border-x-0 border-t-0 focus:outline-none focus:border-[#f0ad1d]"
                                :placeholder="contactSection.phonePlaceholder" />
                        </div>
                    </div>

                    <div class="col-span-3 mb-4">
                        <label for="message" class="block text-sm font-medium">
                            <i class="fa-solid fa-file-lines"></i> {{ contactSection.messageTitle }}
                        </label>
                        <textarea id="message" name="message" v-model="formData.message" rows="4"
                            class="mt-1 p-2 w-full bg-[#353535] focus:outline-none border focus:border-[#f0ad1d] rounded"
                            :placeholder="contactSection.messagePlaceholder" required></textarea>
                    </div>

                    <input type="hidden" name="redirect" value="https://www.cookphoto.ca/success">

                    <button type="submit" class="btn col-span-3 w-full bg-[#f0ad1d]">
                        {{ contactSection.buttonText }} <i class="fa-solid fa-paper-plane"></i>
                    </button>
                </form>
            </div>
        </div>

        <!-- Mobile view -->
        <div class="p-8 text-white block lg:hidden bg-cover bg-center"
            :style="{ 'background-image': 'url(' + contactSection.background + ')' }">
            <div class="p-5 bg-[#353535] rounded">
                <form @submit.prevent="submitForm">
                    <div class="text-center mb-3">
                        <h2 class="text-white text-2xl">Let's work together!</h2>
                        <p class="text-white ">Send me a message and I'll get back to you asap.</p>
                    </div>
                    <div class="grid grid-cols-1 lg:gap-3 w-full text-left">
                        <div class="mb-4 col-span-3 lg:col-span-1">
                            <label for="name" class="block text-sm font-medium">
                                {{ contactSection.nameTitle }}
                            </label>
                            <input type="text" id="name" name="name" v-model="formData.name"
                                class="mt-1 p-2 w-full border bg-[#353535] bg-opacity-90 border-x-0 border-t-0 focus:outline-none focus:border-[#f0ad1d]"
                                :placeholder="contactSection.namePlaceholder" required />
                        </div>

                        <div class="mb-4 col-span-3 lg:col-span-1">
                            <label for="name" class="block text-sm font-medium">
                                {{ contactSection.emailTitle }}
                            </label>
                            <input type="text" id="email" name="email" v-model="formData.email"
                                class="mt-1 p-2 w-full border bg-[#353535] bg-opacity-90 border-x-0 border-t-0 focus:outline-none focus:border-[#f0ad1d]"
                                :placeholder="contactSection.emailPlaceholder" required />
                        </div>

                        <div class="mb-4 col-span-3 lg:col-span-1">
                            <label for="name" class="block text-sm font-medium">
                                {{ contactSection.phoneTitle }}
                            </label>
                            <input type="tel" id="phone" name="phone" v-model="formData.phone"
                                class="mt-1 p-2 w-full border bg-[#353535] bg-opacity-90 border-x-0 border-t-0 focus:outline-none focus:border-[#f0ad1d]"
                                :placeholder="contactSection.phonePlaceholder" />
                        </div>
                    </div>

                    <div class="col-span-3 mb-4">
                        <label for="message" class="block text-sm font-medium">
                            {{ contactSection.messageTitle }}
                        </label>
                        <textarea id="message" name="message" v-model="formData.message" rows="4"
                            class="mt-1 p-2 w-full bg-[#353535] bg-opacity-90 focus:outline-none border focus:border-[#f0ad1d] rounded"
                            :placeholder="contactSection.messagePlaceholder" required></textarea>
                    </div>

                    <input type="hidden" name="redirect" value="https://www.cookphoto.ca/success">

                    <button type="submit" class="btn col-span-3 w-full bg-[#f0ad1d]">
                        {{ contactSection.buttonText }} <i class="fa-solid fa-paper-plane"></i>
                    </button>
                </form>
            </div>
        </div>

    </section>
</template>

<script>
import { contactSection } from '@/constants';

export default {
    data() {
        return {
            formData: {
                name: "",
                email: "",
                phone: "",
                message: "",
                access_key: "c7cbed12-ec5a-4920-a24e-81f72a4143e0",
            },
            contactSection: contactSection,
        };
    },
    methods: {
        async submitForm() {
            try {
                const response = await fetch("https://api.web3forms.com/submit", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    body: JSON.stringify(this.formData),
                });

                if (response.ok) {
                    console.log("Form submitted successfully:", response);

                    alert(contactSection.successText);

                    this.formData.name = '';
                    this.formData.email = '';
                    this.formData.phone = '';
                    this.formData.message = '';
                } else {
                    console.error("Form submission failed:", response);
                    alert(contactSection.failedText);
                }
            } catch (error) {
                console.error("An error occurred during form submission:", error);
                alert(contactSection.failedText + '\n' + error);
            }
        },
    },
};
</script>
  
<style scoped>
/* Add any additional styles as needed */
</style>
  