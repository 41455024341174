<template>
    <footer class="bg-black py-6">
        <div class="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-4 items-center">
            <ul class="flex gap-3 mx-auto md:mx-0">
                <li v-for="link in navLinks" :key="link.icon">
                    <a :href="link.link" target="_blank" rel="noopener noreferrer" class="text-gray-400 hover:text-white">
                        <span class="social-icon">
                            <i :class="link.icon"></i>
                        </span>
                    </a>
                </li>
            </ul>

            <!-- Made by Text -->
            <div class="text-gray-400 flex items-center justify-center">
                <p>Built by <a href="https://tysonpm.ca" target="_blank" class="text-orange-500">TysonPM.ca</a></p>
            </div>

            <!-- Copyright Information -->
            <div class="text-gray-400 text-sm text-center">
                <p>&copy; 2023-{{ year }} Levi Cook</p>
            </div>
        </div>
    </footer>
</template>
  
<script>
import { navLinks } from '@/constants';
export default {
    data() {
        return {
            year: new Date().getFullYear(),
            navLinks: navLinks,
        };
    },
};
</script>
  
<style scoped>
/* Add any additional styles as needed */
</style>
  