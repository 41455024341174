<template>
    <section id="contact-strip">
        <div class="container flex mx-auto mt-8">
            <div class="grid grid-cols-1 text-center w-full text-white">
                <p class="lg:text-xl text-base header-font font-light">{{ aboutSection.contact }}</p>
                <p><a :href="navLinks[2].link"><i :class="navLinks[2].icon" class="text-[#f0ad1d]"></i> {{ navLinks[2].text
                }}</a> <a :href="navLinks[3].link"><i :class="navLinks[3].icon" class="text-[#f0ad1d] ml-4"></i> {{
    navLinks[3].text }}</a></p>
            </div>
        </div>
    </section>
</template>

<script>
import { aboutSection, navLinks } from '@/constants';

export default {
    data() {
        return {
            aboutSection: aboutSection,
            navLinks: navLinks,

        }
    }
}
</script>