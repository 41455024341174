<template>
  <NavBar />
  <PageHeader />
  <AboutSection />
  <ServiceCards />
  <PortfolioSection />
  <!--<TestimonialSection />-->
  <ContactForm />
  <PageFooter />
</template>

<script>
import NavBar from './components/Navigation/NavBar.vue';
import PageHeader from './components/Landing/PageHeader.vue';
import AboutSection from './components/Landing/AboutSection.vue';
import ServiceCards from './components/Landing/ServiceCards.vue';
// import TestimonialSection from './components/Landing/TestimonialSection.vue';
import PortfolioSection from './components/Landing/PortfolioSection.vue';
import ContactForm from './components/Landing/ContactForm.vue';
import PageFooter from './components/Landing/PageFooter.vue';

export default {
  name: 'App',
  components: {
    NavBar,
    PageHeader,
    AboutSection,
    ServiceCards,
    // TestimonialSection,
    PortfolioSection,
    ContactForm,
    PageFooter
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin: 0px;
}

body {
  margin: 0px;
  background-color: black;
}
</style>
