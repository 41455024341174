<!-- Navbar.vue -->
<template>
  <nav :class="{ 'bg-black': isBackgroundBlack, 'md:bg-transparent': !isBackgroundBlack }"
    class="slide-down p-4 fixed w-full z-50 transition-all duration-500 ease-in-out">
    <div class="container mx-auto flex justify-between items-center">
      <div class="flex items-center cursor-pointer" @click="scrollToSection('home')">
        <img :src="navBranding.logo" alt="Logo" class="h-8 w-auto mr-2" />
        <div class="flex flex-col items-center">
          <span class="text-white navbar-font text-lg cursor-pointer">{{ navBranding.text }}</span>
        </div>
      </div>
      <div class="hidden md:flex space-x-4">
        <a @click="scrollToSection('about')" class="text-white cursor-pointer">About</a>
        <a @click="scrollToSection('services')" class="text-white cursor-pointer">Services</a>
        <a @click="scrollToSection('portfolio')" class="text-white cursor-pointer">Portfolio</a>
        <a @click="scrollToSection('contact')" class="text-white cursor-pointer">Contact</a>

        <ul class="flex gap-1">
          <li v-for="link in navLinks" :key="link.icon">
            <a :href="link.link" target="_blank" rel="noopener noreferrer" class="social-link">
              <span class="social-icon">
                <i :class="link.icon"></i>
              </span>
            </a>
          </li>
        </ul>

        <!-- Add more links as needed -->
      </div>
      <!-- Mobile menu button -->
      <button @click="toggleMobileMenu" class="md:hidden text-white focus:outline-none">
        <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7"></path>
        </svg>
      </button>
    </div>

    <!-- Mobile menu -->
    <div v-if="isMobileMenuOpen" class="md:hidden absolute top-16 left-0 right-0 mobile-menu transition"
      style="background-color: #101010;">
      <div class="container mx-auto p-4">
        <a @click="scrollToSection('about')" class="text-white block py-2">About</a>
        <a @click="scrollToSection('services')" class="text-white block py-2">Services</a>
        <a @click="scrollToSection('portfolio')" class="text-white block py-2">Portfolio</a>
        <a @click="scrollToSection('contact')" class="text-white block py-2">Contact</a>

        <hr class="my-3">

        <ul>
          <li v-for="link in navLinks" :key="link.icon">
            <a :href="link.link" target="_blank" rel="noopener noreferrer" class="text-white block py-2">
              {{ link.text }}
            </a>
          </li>
        </ul>
      </div>
    </div>

  </nav>
</template>

<script>
import { navLinks, navBranding } from '@/constants';

export default {
  data() {
    return {
      isMobileMenuOpen: false,
      isBackgroundBlack: false,
      navLinks: navLinks,
      navBranding: navBranding,
    };
  },
  methods: {
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    handleScroll() {
      this.isBackgroundBlack = window.scrollY > 50; // Change the threshold as needed
    },
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);

      if (section) {
        window.scrollTo({
          top: section.offsetTop,
          behavior: 'smooth',
        });
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style scoped>
a:hover {
  color: #f0ad1d;
  transition: color 0.3s ease-in-out;
}

.social-link {
  position: relative;
}

.social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: white;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.5rem;
}

.social-link i {
  z-index: 1;
}

.social-link .fa-instagram {
  line-height: 1.5rem;
}

.social-link:hover .social-icon {
  color: #f0ad1d;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

@keyframes navSlideDown {
  from {
    transform: translateY(-140px);
  }

  to {
    transform: translateY(0);
  }
}

.slide-down {
  animation-delay: 6s;
  animation: navSlideDown 2s ease-out;
}

@keyframes slideDown {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

.mobile-menu {
  animation: slideDown 0.5s ease-in-out;
  z-index: -50;
}

/* Add this class conditionally based on the isMobileMenuOpen state */
.mobile-menu-enter-active,
.mobile-menu-leave-active {
  transition: transform 0.5s ease-in-out;
}

.mobile-menu-enter-to,
.mobile-menu-leave-to {
  transform: translateY(0);
}

.mobile-menu-leave {
  transform: translateY(-100%);
}
</style>
